html {
  scroll-behavior: smooth;
  height: 100vh;
  margin: 0;
}

/* #root {
  overflow-y: auto;
  position: relative; */
/* } */

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  background-color: #fefeff;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 2px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #00a1a9;
}

#scroll-container {
  height: 100vh;
  margin: 0;
  /* overflow-y: scroll; */
  position: relative;
}

section {
  height: 100%;
  display: block;
  position: relative;
}
